import React, { useEffect, useState } from "react";
import {useStaticQuery, graphql} from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import NestedContentRow from "../components/nestedContentRow";
import ALTile from "../components/ALTile";

const CareersPage = () => {
    const imgData = useStaticQuery(graphql`
        query {
            careersHeader: file(relativePath: { eq: "CareersHeaderImg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            fieldWorkerIcon: file(relativePath: { eq: "FieldWorkerIcon2.png" }) {
                childImageSharp {
                    fixed(width: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            globalIcon: file(relativePath: { eq: "GlobalIcon.png" }) {
                childImageSharp {
                    fixed(width: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            lightIcon: file(relativePath: { eq: "LightIcon.png" }) {
                childImageSharp {
                    fixed(width: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            college1: file(relativePath: { eq: "College1.jpg" }) {
                childImageSharp {
                    fixed(width: 175 height: 175) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            college2: file(relativePath: { eq: "College2.jpg" }) {
                childImageSharp {
                    fixed(width: 175 height: 175) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            college3: file(relativePath: { eq: "College3.jpg" }) {
                childImageSharp {
                    fixed(width: 175 height: 175) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    const [headerImgVisible, isHeaderVisible] = useState(false);
    const toggleHeaderImage = _ => {
        if(window.innerWidth <= 1366) {
            return isHeaderVisible(false);
        }
        isHeaderVisible(true);
    };

    useEffect( _ => {
        toggleHeaderImage();
        window.addEventListener("resize", toggleHeaderImage);
        return function () {
            window.removeEventListener("resize", toggleHeaderImage);
        }
    });

    return (
        <Layout pageID={"careers"}>
            <SEO
                title="Careers"
                description="If you are looking for a dynamic organization in which you can broaden your skills, expand your knowledge, develop partnerships, and make a positive impact, then Austin Lane is the right choice for your career."
            />
            <div className="contentRow pageHeader">
                <div className="subContentRow">
                    <NestedContentRow
                        iconData={
                            (headerImgVisible) ?
                                {
                                    iconType: "fluid",
                                    icon: imgData.careersHeader.childImageSharp.fluid,
                                    iconAlt: "Careers Header"
                                }
                                :
                                {}
                        }
                        textData={{
                            level1: "CAREERS",
                            level2: "How We Support You"
                        }}
                    />
                </div>
            </div>
            <div className={"contentRow row1"}>
                <div className={"subContentRow"}>
                    <div className={"header"}>
                        We thrive on new ideas and perspectives that help continually
                        shape our unique organization. We want to hear yours!
                    </div>
                    <div className={"subHeader"}>
                        Here, you will be part of a team working with our organization
                        that delivers unparalleled service and innovative
                        products to customers around the world. This is your chance to work
                        creatively in the software industry and have
                        the opportunity to develop cutting edge technology.
                    </div>
                    <NestedContentRow
                        iconData={{
                            icon: imgData.fieldWorkerIcon.childImageSharp.fixed,
                            iconType: "fixed",
                            iconAlt: "Field Worker Icon"
                        }}
                        textData={{
                            level1: "Build extraordinary products"
                        }}
                    />
                    <NestedContentRow
                        iconData={{
                            icon: imgData.globalIcon.childImageSharp.fixed,
                            iconType: "fixed",
                            iconAlt: "Global Icon"
                        }}
                        textData={{
                            level1: "Be a part of a global mission"
                        }}
                    />
                    <NestedContentRow
                        iconData={{
                            icon: imgData.lightIcon.childImageSharp.fixed,
                            iconType: "fixed",
                            iconAlt: "Light Icon"
                        }}
                        textData={{
                            level1: "Grow your skills and learn new things"
                        }}
                    />
                </div>
            </div>
            <div className={"contentRow row2"}>
                <div className={"subContentRow"}>
                    <div className={"header"}>Culture</div>
                    <NestedContentRow
                        textData={{
                            level1: `Experience career growth and work with like-minded individuals that help you continue to develop your skills. We’re
                                    always focused on employee engagement and excellent communication to help you and everyone succeed, as we
                                    value our employees above all else. Experience a caring, open, and encouraging work environment where you can
                                    express yourself and live your passion. Come work with us and apply by contacting one of the departments below!`
                        }}
                    />
                </div>
            </div>
            <div className={"contentRow row3"}>
                <div className={"subContentRow"}>
                    <div className={"header"}>We provide a multitude of industry-leading benefits, including:</div>
                    <ul className={"benefits"}>
                        <li>Medical Insurance</li>
                        <li>Vacation Pay</li>
                        <li>Sick Pay</li>
                        <li>Dental Insurance</li>
                        <li>Personal Day Pay</li>
                        <li>Tenure Recognition Awards</li>
                        <li>Vision Insurance</li>
                        <li>Holiday Pay</li>
                    </ul>
                </div>
            </div>
            <div className={"contentRow row4"}>
                <div className={"subContentRow"}>
                    <div className={"header"}>Austin Lane College Program</div>
                    <div className={"subHeader"}>
                        Our interns have the incredible opportunity to advance their strengths and interests in their
                        field of study with actual real-world experiences. We offer competitive pay and flexible hours
                        for school schedules as well.
                    </div>
                    <div className={"collegeTiles"}>
                        <ALTile
                            icon={imgData.college1.childImageSharp.fixed}
                            iconType={"fixed"}
                            iconAlt={"College 1"}
                            useWebinar={false}
                        />
                        <ALTile
                            icon={imgData.college2.childImageSharp.fixed}
                            iconType={"fixed"}
                            iconAlt={"College 2"}
                            useWebinar={false}
                        />
                        <ALTile
                            icon={imgData.college3.childImageSharp.fixed}
                            iconType={"fixed"}
                            iconAlt={"College 3"}
                            useWebinar={false}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default CareersPage;